<template>
  <div>
    <h1 class="text-5xl font-bold mb-6">Ready! 🎉</h1>
    <p class="text-2xl font-bold mb-16">Now you can share this link and the guests can answer your questions.</p>
    <div class="bg-white rounded-xl py-8 md:py-12 px-8 md:px-14 flex flex-col border border-gray-300 shadow-lg mb-6">
      <p class="text-sm font-bold mb-7">Shareable link</p>

      <ShareableLink :url="shareableLink" />

      <div class="flex justify-center mt-13">
        <router-link
          class="bg-indigo-gradiant text-white font-bold px-8 py-4 rounded-full flex items-center justify-center hover:opacity-90 text-center"
          :to="{ name: 'Miniviews' }"
        >
          Go to MINIVIEWs™ page
        </router-link>
      </div>
    </div>

    <div class="bg-white rounded-xl py-8 md:py-12 px-8 md:px-14 flex flex-col border border-gray-300 shadow-lg">
      <template v-if="!miniviewExplanation">
        <p class="text-sm font-bold mb-2.5">Explain your MINIVIEW™</p>
        <p class="text-xs text-grayDark mb-7">As an option, you can record an explaination of your MINIVIEW™ that your guest will see once they click on the shareable link and before they begin answering your questions.</p>
        <div class="flex flex-col md:flex-row space-y-1 md:space-y-0 space-x-0 md:space-x-1">
          <modal :with-close-button="false" class="bg-pink-500 text-xs text-blue-800 bg-opacity-10 flex justify-center items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
            <VideoIcon class="text-pink-500 h-4" />
            <span class="ml-3">Record a video</span>

            <template #content="{ close }">
              <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                <RecordVideoAnswer @close="close" :miniview="currentMiniview" @update-miniview="updateMiniviewExplanation($event, 'video_recordings')" />
              </div>
            </template>
          </modal>

          <modal :with-close-button="false" class="bg-pink-500 text-xs text-blue-800 bg-opacity-10 flex justify-center items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
            <MicIcon class="text-pink-500 h-4" />
            <span class="ml-3">Record an audio</span>

            <template #content="{ close }">
              <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                <RecordAudioAnswer @close="close" :miniview="currentMiniview" @update-miniview="updateMiniviewExplanation($event, 'audio_recordings')" />
              </div>
            </template>
          </modal>
        </div>
      </template>
      <template v-else>
        <div class="flex justify-between w-full mb-6">
          <p class="text-sm font-bold text-blue-800">MINIVIEW™ Explanation</p>
          <div class="flex space-x-6">
            <ConfirmDialog
              @confirm="deleteMiniviewExplanation"
              confirm-action="Yes, Delete"
              dialog-title="Delete MINIVIEW™ explanation?"
              dialog-text="Are you sure you want to delete the miniview explanation?"
              class="text-red-500 flex space-x-2 items-center text-sm"
            >
              <TrashIcon class="h-5 w-5" />
              <span class="ml-2">Delete</span>
            </ConfirmDialog>
            <modal ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
              <EditAltIcon class="h-5 w-5" />
              <span>Edit</span>

              <template #content="{ close }">
                <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                  <RecordVideoAnswer
                    v-if="miniviewExplanation.media_type === 'video_recordings'"
                    @close="close"
                    :miniview="currentMiniview"
                    @update-miniview="updateMiniviewExplanation($event, 'audio_recordings')"
                  />
                  <RecordAudioAnswer
                    v-else-if="miniviewExplanation.media_type === 'audio_recordings'"
                    @close="close"
                    :miniview="currentMiniview"
                    @update-miniview="updateMiniviewExplanation($event, 'audio_recordings')"
                  />
                </div>
              </template>
            </modal>
          </div>
        </div>
        <VideoPreview v-if="miniviewExplanation.media_type === 'video_recordings'" :media-src="miniviewExplanation.media_url" />
        <AudioPreview v-else-if="miniviewExplanation.media_type === 'audio_recordings'" :media-src="miniviewExplanation.media_url" />
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapGetters, mapMutations } from 'vuex';
import ShareableLink from "@/components/shared/ShareableLink";
import Modal from '@/components/shared/Modal'
import RecordVideoAnswer from '@/components/bookings/individual/RecordVideoAnswer';
import RecordAudioAnswer from '@/components/bookings/individual/RecordAudioAnswer';
import VideoIcon from '@/components/svgs/VideoIcon';
import MicIcon from '@/components/svgs/MicIcon';
import VideoPreview from '@/components/shared/VideoPreview';
import AudioPreview from '@/components/shared/AudioPreview';
import EditAltIcon from '@/components/svgs/EditAltIcon';
import TrashIcon from '@/components/svgs/TrashIcon';
import ConfirmDialog from '@/components/shared/ConfirmDialog';

export default {
  name: "MiniviewCreated",
  components: { ShareableLink, Modal, RecordVideoAnswer, RecordAudioAnswer, VideoIcon, MicIcon, VideoPreview, AudioPreview, EditAltIcon, TrashIcon, ConfirmDialog },

  data() {
    return {
      miniviewExplanation: null,
    }
  },

  computed: {
    ...mapGetters({
      currentMiniview: 'miniviews/current',
      steps: 'miniviews/steps',
    }),

    shareableLink() {
      return `${process.env.VUE_APP_URL}/miniviews/${this.currentMiniview.uuid}`
    }
  },

  mounted() {
    if (!this.currentMiniview) {
      this.setCurrentStep(1);
      this.$router.push({name: this.steps[0]})
    }
  },

  methods: {
    ...mapMutations({
      setCurrentStep: "miniviews/setCurrentStep",
    }),

    async updateMiniviewExplanation(media, type) {
      console.log(media);
      try {
        await api.put(`/miniviews/${this.currentMiniview.uuid}`, { explanation_media_id: media.id })
        this.miniviewExplanation = {
          media_type: type,
          media_url: media.url,
          media_id: media.id,
        }
      } catch (error) {
        this.$toast.error('There was an error. Media was not saved.')
      }
    },

    async deleteMiniviewExplanation() {
      try {
        await api.put(`/miniviews/${this.currentMiniview.uuid}`, { explanation_media_id: null })
        this.miniviewExplanation = null;
      } catch (error) {
        this.$toast.error('There was an error. Media was not deleted.')
      }
    }
  },
};
</script>
